<template>
  <div class="sign-in" ref="signIn">
    <q-card class="sign-in__card --emphasize">
      <q-card-section class="text-center">
        <div class="flex items-center justify-center">
          <img
            :src="$store.state.branding.logoDark"
            style="width: 185px"
            class="q-my-md"
          />
        </div>
      </q-card-section>

      <q-card-section class="q-py-none">
        <p class="q-pb-xs text-weight-medium">
          Account Sign In
        </p>
        <p class="q-mb-none text-font-secondary">
          Enter your user information below to sign in.
        </p>
      </q-card-section>

      <q-card-section>
        <q-form class="sign-in__form" @submit="validateLogin">
          <div class="row q-col-gutter-y-sm q-col-gutter-x-md">
            <div class="col-12">
              <q-input
                filled
                v-model="userCredentials.email"
                label="Email Address"
                lazy-rules
                :rules="[
                  val =>
                    (val && val.length > 0) ||
                    'Please enter your Email Address.'
                ]"
                :disable="validatingLogin"
                :error="invalidLoginMessage !== ''"
                @focus="invalidLoginMessage = ''"
              />
            </div>
            <div class="col-12">
              <q-input
                filled
                type="password"
                v-model="userCredentials.password"
                label="Password"
                lazy-rules
                :rules="[
                  val =>
                    (val && val.length > 0) || 'Please enter your Password.'
                ]"
                :disable="validatingLogin"
                :error-message="invalidLoginMessage"
                :error="invalidLoginMessage !== ''"
                @focus="invalidLoginMessage = ''"
              />
            </div>
          </div>

          <div class="sign-in__nav-container q-mt-sm">
            <div class="column q-gutter-y-xs">
              <router-link :to="{ name: 'ForgotPassword' }" class="text-subtle">
                Forgot your password?
              </router-link>
              <router-link :to="{ name: 'SignUp' }" class="text-subtle">
                Don't have an account? Sign Up
              </router-link>
            </div>
            <q-btn
              label="Sign In"
              type="submit"
              color="primary"
              :disable="validatingLogin"
              :loading="validatingLogin"
            />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    return {
      userCredentials: {
        email: "",
        password: ""
      },
      validatingLogin: false,
      invalidLoginMessage: ""
    };
  },
  methods: {
    validateLogin() {
      this.validatingLogin = true;

      this.$store
        .dispatch("auth/REQUEST", this.userCredentials)
        .catch(() => {
          this.invalidLoginMessage = "The credentials you entered are invalid.";
        })
        .finally(() => {
          this.validatingLogin = false;
          this.$router.push("/advertiser");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-in {
  @include display-flex(column, center, center);
  @include fullscreen-height-w-titlebar();

  .sign-in__card {
    width: 450px;
  }

  .sign-in__nav-container {
    @include display-flex(row, space-between, center);
  }
}
</style>
